import React, { useState, useEffect } from "react";
import Link from "next/link";

const navConfig = [
  {
    url: "/assurance-construction",
    label: "Assurance Construction",
    tracking: "tracking-gtm-ACView",
  },
  {
    url: "/assurance-cyber",
    label: "Assurance Cyber",
    tracking: "tracking-gtm-CYBERView",
  },
  {
    url: "/protection-juridique",
    label: "Protection Juridique",
    tracking: "tracking-gtm-PJView",
  },
  {
    url: "/multirisque-professionnelle",
    label: "Multirisque Professionnelle",
    tracking: "tracking-gtm-MRPView",
  },
  {
    url: "/assurance-dommages-ouvrage-maison-individuelle",
    label: "Dommages Ouvrage",
    tracking: "tracking-gtm-MRPView",
  },
  {
    url: "/contact",
    label: "Contact",
    tracking: "tracking-gtm-Contact",
  },
];

const FrontHeader = ({ sticky = true, light = false }) => {
  const [showNav, setShowNav] = useState(false);
  const [togglerOpen, setTogglerOpen] = useState(true);

  useEffect(() => {
    if (showNav === true) {
      document.querySelector("body").style.position = "initial";
      if (window.innerWidth < 1100) {
        document.querySelector("body").style.maxHeight = "100svh"; //"100svh";
        document.querySelector("body").style.overflow = "hidden";
      }
    } else {
      document.querySelector("body").style.position = "initial";

      document.querySelector("body").style.maxHeight = "auto";
      document.querySelector("body").style.overflow = "visible";
    }
    window.addEventListener("resize", windowResized);
    return () => {
      window.removeEventListener("resize", windowResized);
    };
  }, [showNav]);

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener("scroll", windowScrolled);
    }, 1000);
    return () => {
      window.removeEventListener("scroll", windowScrolled);
    };
  }, []);

  const windowResized = () => {
    if (window.innerWidth >= 768 && showNav) {
      setShowNav(false);
    }
  };

  const windowScrolled = (e) => {
    let nav = document.querySelector(".main-nav");
    let header = document.querySelector(".front-header");

    if (window.scrollY > 0) {
      nav.classList.add("scrolled");
    } else {
      nav.classList.remove("scrolled");
    }
    if (window.scrollY > window.innerHeight) {
      showEvent();
    } else {
      hideEvent();
    }
  };

  const showEvent = () => {
    var herocta = document.querySelector(".hero-cta span");
    var cta = document.querySelector(".header-cta");
    var ctaText = document.querySelector(".header-cta span");
    //add cta-sticky class to all ctas
    if (cta && herocta) {
      cta.classList.remove("d-none");
      document.querySelector(".header-cta a").href =
        document.querySelector(".hero-cta").href;
      ctaText.innerHTML = herocta.innerHTML || "J'assure mon entreprise";
    }
  };
  const hideEvent = (e) => {
    var cta = document.querySelector(".header-cta");
    //remove cta-sticky class to all ctas
    if (cta) {
      cta.classList.add("d-none");
    }
  };

  return (
    <header
      className={`${
        sticky ? "position-fixed" : "position-relative"
      } w-100 main-nav`}
      style={{
        top: 0,
        zIndex: "9999",
      }}
    >
      <div
        className={`front-header d-flex justify-content-center
        ${showNav ? "bg-white" : ""}
        `}
      >
        <div className="header-logo  ">
          <Link href="/">
            <span
              className={`main-logo-perceval tracking-gtm-IndexView ${
                showNav ? "opened" : ""
              } ${light ? "white" : ""}`}
            ></span>
          </Link>
        </div>

        <nav
          className={`header-links align-items-stretch justify-content-between flex-fill  
            ${showNav ? "show animated fadeIn faster" : ""}
            `}
        >
          {showNav && (
            <div
              onClick={() => setShowNav(false)}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            ></div>
          )}
          <ul
            onClick={() => setTogglerOpen(true)}
            className={" d-center flex-xl-fill p-0 m-0  pt-3 pt-md-0"}
            style={{ gap: "5px" }}
          >
            {navConfig.map((item, index) => (
              <li
                key={index}
                className={`nav-item text-center align-self-stretch align-items-center justify-content-center d-center 
                  ${showNav ? "animated fadeInRight faster" : ""}   ${
                  light ? "white" : ""
                }
                  `}
                style={{
                  animationDelay: `${index * 0.03}s`,
                }}
              >
                <Link href={item.url}>
                  <a>
                    <span
                      className={`nav-link ${item.tracking} ${
                        light ? "white" : ""
                      }`}
                    >
                      {item.label}
                    </span>
                  </a>
                </Link>
              </li>
            ))}
          </ul>
          <ul className="header-right d-center pl-0 m-0">
            <li
              className={`nav-item text-center align-self-stretch align-items-center justify-content-center d-center 
              ${showNav ? "animated fadeInRight faster bg-secondary" : ""}  ${
                light ? "white" : ""
              }
              `}
              style={{
                animationDelay: `${navConfig.length * 0.03}s`,
              }}
            >
              <a
                className={`nav-link ${showNav ? "text-white" : ""} ${
                  light ? "white" : ""
                }`}
                href="/mon-compte"
              >
                <strong>Mon Compte</strong>
              </a>
            </li>
          </ul>
        </nav>
        <ul className="list-group mr-md-0 list-unstyled">
          <li className="nav-item text-center header-cta animated fadeInRight faster d-none">
            <a
              href="/assurer-mon-entreprise"
              className="btn btn-secondary d-center p-2 tracking-gtm-FormView"
            >
              <span>J'assure mon entreprise</span>
            </a>
          </li>
        </ul>

        <button
          onClick={() => setShowNav((n) => !n)}
          className={`header-burger  btn btn-default border-0 p-0 h-100 mb-0 ${
            showNav ? "opened" : ""
          } ${light ? "white" : ""}`}
          type="button"
        >
          <svg
            height="30"
            width="30"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="bars"
            className="svg-inline--fa fa-bars fa-w-14 text-primary"
            role="img"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
            />
          </svg>
        </button>
      </div>
    </header>
  );
};

export default FrontHeader;
