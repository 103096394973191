const Metas = [
  {
    path: "/",
    title: "Perceval • L’assurance chevaleresque des entreprises",
    description:
      "Trouvez votre entreprise en 1 clic et découvrez les solutions d’assurance de Perceval pour votre activité.",
    image: "accueil.jpg",
  },

  {
    path: "/protection-juridique",
    title: "Assurance Protection Juridique en ligne • France & DROM",
    description:
      "Protégez votre entreprise des litiges à l’amiable et au judiciaire. Des juristes spécialisés à votre service 6j/7.  Prise en charge des frais de justice jusqu’à 50 000€ par an. Accès permanent à la base de connaissance Digidroit (textes de loi, modèles de lettres, jurisprudences, etc.).",
    image: "protection-juridique.jpg",
  },
  {
    path: "/assurance-construction",
    title: "Assurance Décennale en ligne • France & La Réunion",
    description:
      "Découvrez l'Assurance Construction par Perceval. Une offre sérieuse en 3 jours auprès des assureurs les plus solides. Demande en 3 minutes.",
    image: "assurance-construction.jpg",
  },
  {
    path: "/multirisque-professionnelle",
    title: "Assurance Multirisque Professionnelle en ligne • France & DROM",
    description:
      "Remplissez un formulaire unique et obtenez un PDF conforme chez tous les assureurs pour simplifier votre recherche ou mettre à jour un contrat existant. Obtenez une offre tarifaire de Perceval pour les entreprises basées à La Réunion.",
    image: "multirisque-professionnelle.jpg",
  },
  {
    path: "/devenir-partenaire",
    title: "Devenir Partenaire",
    description: "Devenez partenaire de Perceval.",
    image: "devenir-partenaire.jpg",
  },
  {
    path: "/cgv",
    title: "Conditions Générales de Vente",
    description:
      "Retrouvez les Conditions Générales de Vente de Perceval. Description des services en ligne, gestion des données personnelles, droit de rétractation.",
    image: "",
  },
  {
    path: "/mentions-legales",
    title: "Mentions Légales",
    description:
      "Retrouvez les Mentions Légales de Perceval et nos conditions d'utilisation des données personnelles en conformité avec le RGPD.",
    image: "",
  },
  {
    path: "/politique-de-confidentialite",
    title: "Politique de confidentialité",
    description:
      "Retrouvez la politique de confidentialité en conformité avec le RGPD.",
    image: "",
  },
  {
    path: "/assurer-mon-entreprise",
    title: "Assurer mon entreprise",
    description:
      "Découvrez les produits et services d’assurance sur-mesure de Perceval pour votre entreprise. Assurance Décennale. Protection Juridique. Multirisque Professionnelle.",
    image: "",
  },
  {
    path: "/contact",
    title: "Contacter Perceval",
    description:
      "Contactez l'équipe Perceval par tchat, email, et téléphone. Inscrivez-vous à la newsletter.",
    image: "contact.jpg",
  },
  {
    path: "/assurance-dommages-ouvrage-maison-individuelle",
    title:
      "Dommages Ouvrage Maison Individuelle en ligne • France & La Réunion",
    description:
      "Assurez votre projet de construction ou de rénovation jusqu’à 500 000€.",
    image: "dommages-ouvrage.jpg",
  },
  {
    path: "/assurance-dommages-ouvrage-maison-individuelle/tarif",
    title:
      "Tarif en ligne Dommages Ouvrage Maison Individuelle • France & La Réunion",
    description:
      "Obtenez un tarif pour l’assurance de votre projet de construction ou de rénovation en 3 minutes.",
    image: "",
  },
  {
    path: "/formulaire-simplifie/[lead_uuid]",
    title: "Offre d’assurance pour votre entreprise • Perceval",
    description:
      "Découvrez les produits et services d’assurance sur-mesure de Perceval pour votre entreprise. Assurance Décennale. Protection Juridique. Multirisque Professionnelle.",
    image: "formulaire-simplifie.jpg",
  },
  {
    path: "/assurance-cyber",
    title: "Assurance Cyber en ligne • France & DROM",
    description:
      "La cyberdéfense de votre entreprise. Gestion de crise 24/7 en cas d’attaque. Indemnisation complète (ransomware, détournement de fonds, perte d’exploitation, etc.). Restauration de vos données. Surveillance proactive. Assistance Juridique.",
    image: "assurance-cyber.jpg",
  },
  {
    path: "/mon-compte",
    title: "Perceval • Mon compte",
    description: "Clients et partenaires, accédez à votre espace sécurisé.",
    image: "",
  },
];

export default Metas;
