import React from "react";
import Link from "next/link";
import { DateTime } from "luxon";
import SocialLinks from "./common/SocialLinks";

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="row">
            <div className="col-12">
              <img
                src="/images/home/icons/logo.svg"
                className="footer-logo"
                alt="Logo Perceval"
              />
              <strong className="footer-title">Perceval SAS</strong>
              <p>
                <a href="mailto: bonjour@goperceval.fr">
                  bonjour@goperceval.fr
                </a>
              </p>
              <p>20 rue de La Fédération 75015 Paris</p>
              <p>
                14 allée des Primevères
                <br />
                97490 Ste-Clotilde - La Réunion
              </p>
              <strong className="footer-title">Suivez-nous</strong>
              <SocialLinks containerClassName="d-flex align-items-center justify-content-start" />
              <a
                href="https://smartlink.ausha.co/entreprendre-avec-assurance"
                target="_BLANK"
                className="font-weight-bold"
              >
                🎙️ Écouter "Entreprendre avec assurance" le podcast de Perceval
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8">
          <div className="row">
            <div className="col-12 col-md-4">
              <strong className="footer-title">Assurance</strong>
              <Link href="/assurance-construction">
                <a>
                  <span className="tracking-gtm-ACView">
                    Assurance Construction
                  </span>
                </a>
              </Link>
              <Link href="/assurance-cyber">
                <a>
                  <span className="tracking-gtm-PJView">Assurance Cyber</span>
                </a>
              </Link>
              <Link className="link" href="/protection-juridique">
                <a>
                  <span className="tracking-gtm-PJView">
                    Protection Juridique
                  </span>
                </a>
              </Link>
              <Link href="/multirisque-professionnelle">
                <a>
                  <span className="tracking-gtm-ACView">
                    Multirisque Professionnelle
                  </span>
                </a>
              </Link>
              <Link href="/assurance-dommages-ouvrage-maison-individuelle">
                <a>
                  <span className="tracking-gtm-DOMIView">
                    Dommages Ouvrage
                  </span>
                </a>
              </Link>
            </div>
            <div className="col-12 col-md-4 pr-0">
              <strong className="footer-title">Plus sur Perceval</strong>
              <a href="https://blog.goperceval.fr" target="_BLANK">
                Blog
              </a>
              <Link href="/contact">
                <a>
                  <span className="tracking-gtm-Contact">Contact</span>
                </a>
              </Link>
              <Link href="/mentions-legales">
                <a>
                  <span>Mentions légales</span>
                </a>
              </Link>
              <Link href="/cgv">
                <a>
                  <span>CGV</span>
                </a>
              </Link>
              <Link href="/politique-de-confidentialite">
                <a>
                  <span>Politique de confidentialité</span>
                </a>
              </Link>
              <Link href="/devenir-partenaire">
                <a>
                  <span>Devenir Partenaire</span>
                </a>
              </Link>
              {/* <a href="javascript:showAxeptioButton()">Cookies</a> */}
            </div>
            <div className="col-12 col-md-4 special-footer-section">
              <strong className="footer-title">Réclamation</strong>
              <p className="small">
                Nous écrire à reclamation@goperceval.fr ou via l’espace en
                ligne. Si échec de la réclamation saisir le Médiateur de
                l’Assurance sur www.mediation-assurance.org ou à l’adresse : La
                Médiation de l’Assurance, Pôle Planète CSCA, TSA 50110, 75441
                Paris Cedex 09.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12">
          <hr />
          <p
            className="m-0 pt-3 text-left text-md-center"
            style={{ fontSize: 13, color: "black" }}
          >
            Perceval est une Société par Actions Simplifiée (SAS) au capital de
            1.500.000€ entièrement libéré (Siren : 390469765 R.C.S.
            Saint-Denis), régie par le Code des Assurances et enregistrée à
            l’Orias sous le N°07.019.124. Perceval est soumise au contrôle de
            l’Autorité de Contrôle Prudentiel et de Résolution (ACPR), 4 place
            de Budapest, 75009 Paris. © Copyright{" "}
            {DateTime.local().toFormat("yyyy")} | Designed with ♡ by 10positif
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
