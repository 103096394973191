import Head from "next/head";
import { useRouter } from "next/router";
import Metas from "./common/Metas";

const Header = () => {
  var router = useRouter();

  const metas = Metas.find((m) => m.path == router.pathname);

  return (
    <Head>
      <title>{metas?.title || "Titre"}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <link rel="icon" href="/Favicons/favicon.ico" />
      <meta name="description" content={metas?.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.goperceval.fr/" />
      <meta property="og:title" content={metas?.title} />
      <meta property="og:description" content={metas?.description} />
      <meta
        property="og:image"
        content={"https://www.goperceval.fr/images/ogimages/" + metas?.image}
      ></meta>
      <meta
        name="facebook-domain-verification"
        content="cu24kgvgpnmvku7qgcbsjjdb0t51i7"
      />
      <link rel="manifest" href="/manifest.json" />
      <link rel="apple-touch-icon" href="logo192.png" />

      <link href="css/bootstrap-material.css" rel="stylesheet" />

      {/* <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
          integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
          crossOrigin=""
        /> */}
      {/* import font from /fonts/fonts-satoshi.css */}

      <link
        rel="stylesheet"
        href="/fonts/fonts-satoshi.css"
        crossOrigin="anonymous"
      />

      {process.env.NODE_ENV === "production" ? (
        <>
          <script
            defer
            type="text/javascript"
            src="/vendor/consoleProd.js"
            crossOrigin="true"
          ></script>

          <script
            defer
            data-cookieconsent="ignore"
            src="/vendor/tracking.js"
          ></script>
        </>
      ) : (
        <script defer type="text/javascript">
          const dataLayer = [];
        </script>
      )}

      {/* <script
          src="https://config.metomic.io/config.js?id=prj:a7940763-77b3-46f2-8cb3-ec8869f1bf07"
          crossOrigin="true"
          charSet="utf-8"
          defer
        ></script>
        <script
          src="https://consent-manager.metomic.io/embed.js"
          crossOrigin="true"
          charSet="utf-8"
          defer
        ></script> */}
      {/* <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/paymentfont/1.2.5/css/paymentfont.min.css"
          integrity="sha512-cTUjrFeSc3V1zREbjSfJg3IuCA+sC53ZtUMboTE4YtI7Fss/8mH5P7X35hEucJPiFc7B7aax2wTM6XFrKK7mRQ=="
          crossOrigin="anonymous"
        /> */}
    </Head>
  );
};

export default Header;
