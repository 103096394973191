const Colors = {
  primary: "#040CA5",
  secondary: "#3E75FF",
  success: "#20a826",
  blue: "#f1f2fe",
  default: "#898989",
  yellow: "#EF9600",
};

export default Colors;
